.flag-container {
  width: 66%;
  overflow: hidden;

  box-shadow: 2px 2px 2px rgba(50, 50, 50, 0.25);
  border-radius: 0.5em;

  margin: 0 auto;

  @media (min-width: 425px) {
    max-width: 45%;
  }
  @media (min-width: 1024px) {
    max-width: 21%;
  }

  img {
    width: 100%;
  }
}
