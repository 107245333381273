.options {
  &-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    padding: 0.25em 1em;
    margin: 0 auto;
  }

  &__rounds {
    font-size: 0.95rem;
    color: white;
    background-color: #546438;

    border-radius: 5px;

    padding: 0.5em;
    margin-bottom: 0.5em;
  }

  &__title {
    font-size: 1.5rem;
    color: rgba(25, 25, 25, 0.85);
    //color: rgba(255, 255, 255, 0.8);
    text-align: center;

    margin-bottom: 0.5em;
  }

  &__list {
    display: flex;
    flex-direction: column;
    justify-content: center;

    list-style-type: none;
    padding: 0;
    height: 66%;

    &__option {
      width: 100%;

      background-color: wheat;
      color: rgba(50, 50, 50, 1);

      font-weight: 500;
      font-size: 1.15rem;
      text-align: center;

      box-shadow: rgba(50, 50, 50, 0.25) 2px 2px 2px;
      border-radius: 0.5em;

      padding: 0.5em 0.75em;
      margin-bottom: 0.5em;

      &:hover {
        cursor: pointer;

        background-color: rgba(50, 50, 50, 1);
        color: wheat;
      }
    }
  }
}

.answer {
  &-container {
    width: 95%;

    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    border-radius: 5px;

    padding: 1.5em 1em;
    margin: 0 auto 1.5em;

    @media (min-width: 425px) {
      max-width: 66%;
    }
    @media (min-width: 1024px) {
      max-width: 33%;
    }

    &--correct {
      background-color: rgba(0, 255, 0, 0.7);
      color: white;
    }

    &--wrong {
      background-color: rgba(255, 0, 0, 0.7);

      color: white;
    }
  }

  &__text {
    display: flex;
    flex-direction: column;

    font-size: 1.25rem;
    text-align: center;

    border-radius: 0.25em;

    margin-bottom: 0.5em;

    p {
      margin-bottom: 0.5em;
    }
  }
}

.final-score {
  position: absolute;
  top: 0;
  left: 0;

  width: 100%;
  height: 100vh;

  background-color: rgba(0, 0, 0, 0.6);

  &__content-container {
    position: relative;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);

    width: 95%;
    height: 90%;

    overflow-y: hidden;

    border-radius: 5px;
    background-color: rgba(238, 235, 237, 0.75);
    color: black;

    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    font-size: 18px;
    text-align: center;

    padding: 2em 1em;

    @media (min-width: 425px) {
      max-width: 66%;
    }
    @media (min-width: 1024px) {
      max-width: 33%;
    }

    h2 {
      margin-bottom: 1em;
      text-transform: uppercase;
    }

    p:first-of-type {
      font-weight: bold;
      margin-bottom: 0.5em;
    }

    p:last-of-type {
      margin-bottom: 1em;
      font-style: italic;
    }
  }
}

.button {
  width: 100px;
  height: 100px;

  font-size: 0.9rem;
  text-transform: uppercase;
  font-weight: 700;

  background-color: #546438;
  color: wheat;

  border: none;
  border-radius: 50%;
  box-shadow: rgba(50, 50, 50, 0.25) 2px 2px 2px;
  padding: 1em;

  &:hover {
    cursor: pointer;
  }
}
