* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

body {
  font-family: Roboto, Oxygen, Ubuntu, Cantarell, "Open Sans", "Helvetica Neue",
    sans-serif;
  background-color: #cfc7bc;
  background-image: url(../world.jpg);
  background-repeat: no-repeat;
  background-position: top;
  background-size: cover;
  min-height: 100vh;
}

.loader-container {
  margin-top: 2em;
  text-align: center;

  p {
    margin: 2em;
    color: rgba(255, 255, 255, 0.8);
    font-size: 1.15rem;
  }

  .loader {
    margin: auto;
    height: 50px;
    width: 50px;

    border-radius: 50%;
    border: solid 7px;
    border-top-color: #f3b431;
    border-bottom-color: #228daf;
    border-left-color: #f3b431;
    border-right-color: #f3b431;

    animation: spinldr 1s linear 300ms infinite;
  }

  @keyframes spinldr {
    0% {
      transform: rotate(0deg) scale(1);
    }
    50% {
      transform: rotate(180deg) scale(1.05);
      /*NOOT: zonder rotate wordt transform overschreven*/
    }
    100% {
      transform: rotate(360deg) scale(1);
      /*om sneller te laten draaien kan je 720deg instellen*/
    }
  }
}
