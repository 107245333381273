.hero {
  position: relative;
  padding: 2.5em 1em;
  margin-bottom: 1em;

  &__title {
    color: #f3b431;

    font-size: 2rem;
    text-align: center;
    text-shadow: 3px 3px 0 rgba(0, 0, 0, 0.15);
    text-transform: uppercase;
  }
}
